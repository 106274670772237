.sir-app-header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: $spacing-stack-16;

  > .sir-link {
    display: block;
    margin-bottom: $spacing-stack-8;
  }

  &-content,
  &-controls {
    margin-bottom: $spacing-stack-24;
  }
}
