.sir-app-container {
  max-width: 100%;
  margin: $spacing-stack-64 auto 0;
  padding: 0 $spacing-inline-16;

  @media (min-width: 62em) {
    width: 1024px;
  }
}

.sir-modal-body {
  overflow-y: scroll;
  max-height: calc(100vh - 300px);
}

.sir-modal-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.sir-modal-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
