.sir-login {
  &-logo {
    max-width: 126px;
    margin: 0 auto $spacing-stack-24;
  }

  .sir-card-container--shadow {
    @include spacing-inset-24;
  }

  &-content {
    text-align: center;

    &-body {
      max-width: 500px;
      margin: 0 auto;
    }

    .sir-btn--primary {
      margin: $spacing-stack-12 auto $spacing-stack-40;
    }
  }
}
