.sir-irt {
  margin-top: $spacing-stack-24;
  .sir-irt-select {
    display: flex;
  }
  .sir-card.sir-card-container--border {
    border: 1px solid $color-foreground-tertiary;

    &.checked {
      background: $color-tint-purple-secondary;
      border: 1px solid $color-tint-purple-primary
    }

    &:first-child {
      margin-right: $spacing-inline-16;
    }
    width: unset;
    padding: $spacing-inline-16 $spacing-inline-24;
    & .sir-form-field-label {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}
