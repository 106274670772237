.sir-saved-reports {
  display: flex;
  flex-flow: column wrap;
  min-height: 400px;
  padding-bottom: $spacing-stack-24;

  &-empty {
    display: flex;
    max-width: 240px;
    margin: auto;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    > .sir-type-style--bold,
    > .sir-icon {
      margin-bottom: $spacing-stack-16;
    }

    > .sir-icon {
      width: 60px;
      height: 60px;
    }
  }
}

.sir-modal--delete {
  .sir-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: $spacing-stack-24;
  }

  .sir-btn--danger {
    margin-left: $spacing-inline-16;
    background-color: $color-tint-red-primary;
    color: $color-foreground-on-tint;

    &:hover,
    &:focus {
      background: darken($color-tint-red-primary, 5%);
    }

    &:active {
      background: darken($color-tint-red-primary, 10%);
    }
  }
}
