.sir-report-export {
  &-pdf {
    @include spacing-inset-24;
    @include at-screen-large {
      display: flex;
      padding: 56px;
    }

    &-content {
      flex: 0 0 385px;
      margin-bottom: $spacing-stack-24;

      .sir-report-export-pdf--error & {
        margin: auto;
      }

      @include at-screen-large {
        margin-right: $spacing-inline-64;
        margin-bottom: 0;
      }

      > .sir-type-heading {
        margin-bottom: $spacing-stack-16;
      }
    }

    &-content-controls {
      display: flex;
      flex-flow: column wrap;
      margin-top: $spacing-stack-40;

      > .sir-btn {
        width: 100%;
        margin-bottom: $spacing-stack-12;

        &--with-icon {
          display: inline-block;
          text-align: center;
        }
      }
    }

    &-preview {
      flex: 1 1 605px;

      > iframe {
        max-width: 100%;
      }
    }

    &-preview-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $spacing-stack-16;
    }
  }

  .sir-icon-label {
    display: inline-flex;
    align-items: center;
    margin-bottom: $spacing-stack-8;

    > .sir-icon {
      width: 24px;
      height: 24px;
      margin-right: $spacing-inline-8;
    }

    > .sir-type-display--small {
      color: #666;
    }
  }

  .sir-print-description {
    padding: $spacing-stack-16 0;
  }

  .sir-modal-footer {
    display: flex;
    justify-content: flex-end;

    .sir-link {
      > .sir-icon {
        margin-left: 0;
      }
    }
  }
}

.sir-report-export-pdf-content {
  .sir-skeleton-loader {
    &:first-child {
      margin-bottom: $spacing-stack-8;
    }

    &:nth-child(2) {
      margin-bottom: $spacing-stack-4;
    }

    &:nth-child(3) {
      margin-bottom: $spacing-stack-24;
    }

    &:nth-child(n + 4) {
      margin-bottom: $spacing-stack-4;
    }
  }
}