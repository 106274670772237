.sir-report-builder {
  margin-bottom: $spacing-stack-64;

  .sir-accordion {
    margin-top: $spacing-stack-24;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-stack-40;
  }
}

.sir-report-preview--mini .sir-report-preview-border {
  width: 0px;
}

/***********************************************/
//  STATE SELECTION ACCORDION
/***********************************************/
.sir-accordion-content-state-change {
  display: flex;
  align-items: baseline;

  .sir-skeleton-loader {
    margin-right: $spacing-inline-8;
  }
}

/***********************************************/
//  DATA SELECTION ACCORDION
/***********************************************/
.sir-accordion-content-data {
  &-header {
    margin-bottom: $spacing-stack-40;
  }

  &-body {
    display: none; // Hidden when collapsed

    .sir-accordion--expanded & {
      display: block;
    }
  }

  .sir-report-preview-investment-wrapper {
    margin: $spacing-stack-40 auto;
  }
  .sir-report-preview-investment-text {
    text-align: center;
  }
  .sir-report-preview-investment-figure {
    color: #006CB5;
  }

  .sir-stat-caption--empty {
    height: 10px;
  }
}

/***********************************************/
//  FEATURED CONTENT ACCORDION
/***********************************************/
.sir-accordion-content-selection {
  .sir-report-preview--mini {
    width: auto;
    height: auto;
  }
}

.sir-accordion-content-featured {
  &-header {
    border-bottom: $border-default;

    .sir-form-field {
      margin: $spacing-stack-24 0 $spacing-stack-32;
      max-width: 245px;
    }
  }

  &-body {
    display: none; // Hidden when collapsed
    margin: $spacing-stack-40 0;

    .sir-accordion--expanded & {
      display: block;
    }
  }
}

.sir-featured-options-preview {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-stack-40;

  @include at-screen-large {
    flex-direction: row;
  }

  .sir-featured-options {
    flex: 1 1 auto;
    padding-right: $spacing-inline-24;
  }

  .sir-featured-preview {
    display: block;
    margin-top: $spacing-stack-40;
    
    @include at-screen-large {
      margin-top: 0;
      display: block;
      flex: 0 0 500px;
    }
  }
  
  .sir-featured-content {
    padding: 36px;
    margin-left: -40px;
    margin-right: -40px;

    @include at-screen-large {
      padding: 0 30px;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .sir-featured-content.sir-featured-content--map {
    flex-flow: wrap-reverse;

    @include at-screen-large {
      padding: 0 30px;
      flex-flow: row wrap;
    }
  }
  
  .sir-featured-content--map, .sir-featured-content--photo, .sir-featured-content-caption-wrapper {
    background: #f2f2f2;
  }

  .sir-feature-preview-mobile-note {
    margin-top: $spacing-stack-32;
    font-style: italic;
  }

  .sir-featured-content-caption-heading {
    margin-bottom: revert;

    @include at-screen-large {
      margin-bottom: 0;
    }
  }
}

/***********************************************/
//  PHOTO HEADER
/***********************************************/
.sir-accordion-content-photoheader-title{
  color: $color-tint-purple-primary;
  font-weight: bold;
  margin-bottom: $spacing-stack-12;
}
.sir-accordion-content-photoheader-title-nat{
  color: $color-tint-purple-primary;
  font-weight: bold;
  margin-bottom: $spacing-stack-12;
  margin-top: $spacing-stack-24;
}


.sir-accordion-content-photoheader {
  &-body {
    display: none; // Hidden when collapsed

    .sir-accordion--expanded & {
      display: block;
    }
  }
}
.sir-radio-group>.sir-form-field:last-child {
  border-bottom: 0;
}

/***********************************************/
//  REPORT REVIEW
/***********************************************/
.sir-report-review {
  margin-top: $spacing-stack-40;

  @include at-screen-large {
    display: flex;
  }

  &-content,
  &-preview {
    flex: 1 1 50%;
  }

  &-content {
    margin-bottom: $spacing-stack-24;

    @include at-screen-large {
      margin-bottom: 0;
      padding-right: 80px;
    }

    .sir-type-heading {
      margin-bottom: $spacing-stack-24;
    }

    .sir-form-field {
      margin-top: $spacing-stack-16;
    }
  }

  &-content-controls {
    display: flex;
    flex-flow: column wrap;
    margin-top: $spacing-stack-32;

    > .sir-btn {
      width: 100%;
      margin-bottom: $spacing-stack-16;
    }
  }

  &-preview {
    overflow: auto;
    
    @include at-screen-large {
      overflow: hidden;
    }
    
    &--show-on-mobile {
      display: block;
      margin: $spacing-stack-40 0;
      
      @include at-screen-large {
        display: none;
      }
      
      div.sir-mobile-disclaimer {
        margin-top: $spacing-stack-40;
        font-style: italic;
      }
    }
    &--hide-on-mobile {
      display: none;
      @include at-screen-large {
        display: block;
      } 
    }
  }
}
//cexp-2405 to wrap long headings
.sir-type-heading-text{
  width: fit-content;
}
