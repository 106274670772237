.sir-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &-message {
    text-align: center;
  }
}
